import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Quarkly export
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://aventurinesky.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://aventurinesky.com/img/7419954.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://aventurinesky.com/img/7419954.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://aventurinesky.com/img/7419954.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://aventurinesky.com/img/7419954.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://aventurinesky.com/img/7419954.png"} />
			<meta name={"msapplication-TileImage"} content={"https://aventurinesky.com/img/7419954.png"} />
		</Helmet>
		<Components.Header />
		<Section
			padding="40px 0 40px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)),url(https://aventurinesky.com/img/1.jpg) center center/cover no-repeat"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					The Ultimate Pitch
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
					max-width="900px"
				>
					Bienvenido a The Ultimate Pitch, donde su pasión por el fútbol se encuentra con instalaciones de primera categoría y un servicio inigualable. Si usted es un jugador experimentado que busca perfeccionar sus habilidades, un grupo de amigos en busca de diversión competitiva, o una organización que necesita un campo de nivel profesional para eventos, tenemos todo lo que necesita para disfrutar al máximo del deporte rey.
				</Text>
				<Button
					margin="0px 15px 0px 0px"
					padding="12px 50px 12px 50px"
					background="--color-primary"
					border-radius="8px"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="--primary"
					hover-background="rgba(63, 36, 216, 0)"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					target="_blank"
				>
					Contáctenos
				</Button>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="20%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://aventurinesky.com/img/2.jpg"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="20%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://aventurinesky.com/img/3.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="20%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://aventurinesky.com/img/4.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="20%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://aventurinesky.com/img/5.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="20%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://aventurinesky.com/img/6.jpg"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					lg-width="100%"
					md-text-align="left"
				>
					En The Ultimate Pitch, creemos en ofrecer algo más que un campo de fútbol: ofrecemos una experiencia. Nuestras modernas instalaciones están diseñadas para jugadores de todos los niveles, desde aficionados entusiastas hasta atletas profesionales. Nuestro compromiso con la excelencia garantiza que cada partido jugado en nuestro campo sea memorable y agradable. Con terrenos meticulosamente mantenidos y servicios de primera calidad, nos esforzamos por crear un ambiente donde usted puede jugar su mejor juego.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0" quarkly-title="Advantages/Features-21">
			<Override slot="SectionContent" max-width="1100px" sm-min-width="280px" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 900 42px/1.2 --fontFamily-mono"
				padding="0px 0px 20px 0px"
				width="100%"
				text-align="center"
			>
				¿Por qué elegir The Ultimate Pitch?
			</Text>
			<Box
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="64px"
				md-grid-template-columns="1fr"
				md-grid-gap="48px"
			>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Césped de alta calidad
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Nuestro campo cuenta con césped sintético de primera calidad que imita la hierba natural, garantizando un rendimiento óptimo y reduciendo el riesgo de lesiones.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Iluminación e instalaciones
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Disfruta de los partidos nocturnos con nuestro sistema de iluminación de calidad profesional, y aprovecha nuestros vestuarios y duchas limpios y modernos.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Accesibilidad
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Situado convenientemente con un amplio aparcamiento, nos aseguramos de que llegar al partido sea lo más fácil posible.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex" align-items="flex-start">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
					Mantenimiento profesional
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Nuestros terrenos son mantenidos regularmente por expertos para proporcionar una superficie de juego de alta calidad constante.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="0 0 0 0" inner-width="100%" quarkly-title="Stages/Steps-1" id="about">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Image
					src="https://aventurinesky.com/img/7.jpg"
					display="block"
					width="45%"
					height="100%"
					object-fit="cover"
					md-width="100%"
					max-height="450px"
				/>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="70px 70px 70px 70px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					lg-padding="50px 50px 50px 50px"
					md-width="100%"
					md-padding="80px 50px 80px 50px"
					sm-padding="80px 25px 80px 25px"
					align-self="flex-start"
				>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 900 42px/1.2 --fontFamily-mono"
						border-style="solid"
						border-width="0 0 4px 0"
						border-color="--color-primary"
						padding="0px 0px 20px 0px"
						width="100%"
					>
						Як Грати
					</Text>
					<Box
						width="100%"
						align-items="flex-start"
						lg-align-items="center"
						sm-margin="0px 0px 20px 0px"
						display="flex"
						flex-direction="column"
						lg-width="100%"
						lg-margin="0px 0px 0px 0px"
						sm-padding="0px 0px 0px 0px"
						padding="16px 16px 16px 16px"
						lg-padding="0px 16px 16px 16px"
					>
						<Text
							margin="0px 0px 0px 0px"
							color="--darkL2"
							font="--base"
							lg-text-align="center"
							lg-width="100%"
							md-text-align="left"
						>
							Venga a disfrutar del mejor alquiler de campos de fútbol de la zona en The Ultimate Pitch. Reserve su campo hoy y únase a una comunidad de jugadores apasionados dedicados al juego.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});